




















































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import {
  PostEva,
  PostAll,
  PostStatsMonth,
  PostStatsHospital,
  PostStatsPeople,
  PostStatsNeirong,
  PostStatsTizhi,
  PostStatsXinli,
  PostStatsPopulation,
  GetFilterOption,
  getRenkouData,
  getOverviewData,
  getBingliChart,
} from "@/request/differences";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
import TimeSelect from "@/components/time-select.vue";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    MainHeader,
    NavLeft,
    TimeSelect,
    Empty,
  },
})
export default class Name extends Vue {
  private timeOpt: any = ["全部"];
  private timeOpt2: any = ["全部"];
  // private timeOpt: any = ["全部", "最近1年", "最近6个月", "最近1个月"];
  // private timeOpt2: any = ["全部", "最近1年", "最近30天", "最近7天"];
  private sourceOpt: any = ["全部", "住院", "门诊"];
  private data: any = {
    平台概览: {
      住院: {
        今日住院: "120",
        本月住院: "3514",
        总住院次数: "27849",
      },
      门诊: {
        今日门诊: "120",
        本月门诊: "3514",
        总门诊次数: "27849",
      },
    },
    病例: {
      data: [],
    },
    人口统计: {
      年龄分布: {
        data: [],
      },
      性别分布: {
        data: [],
      },
    },
  };
  private filters: any = {
    病例: {
      统计时间: "全部",
      病例来源: "全部",
      开始时间: "",
      结束时间: "",
    },
    人口统计: {
      统计时间: "全部",
      病例来源: "全部",
      开始时间: "",
      结束时间: "",
    },
  };
  private yuequshi: any = {};
  private jigouqushi: any = {};
  private fuwuliangfenbuBingtu: any = {};
  private zhongdianPies: any = [];
  private zongtiPie: any = {};
  private tizhiOption: any = {};
  private xinliOption: any = {};
  private zhengxingOption: any = {};
  private juminBar: any = {};
  private ageOptions: any = {};
  private selectedHospitals: any[] = [];
  private selectedDoctors: any[] = [];
  private canChooseHospitals: any[] = [];
  private renqunOptions: any = [
    "全部",
    "老年人",
    "儿童",
    "高血压",
    "糖尿病",
    "女性",
    "亚健康",
  ];
  private colors: any = [
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
  ];
  private pieColors: any = [
    "#50A7F9",
    "#69D688",
    "#FEDB57",
    "#BA95F8",
    "#F4AF6E",
    "#F88AA9",
  ];
  private tizhiTypes: any[] = [
    "中医体质辨识",
    "四诊合参体质辨识",
    "0-6岁儿童体质辨识",
    "7-12岁儿童体质辨识",
    "老年人体质辨识",
    "备孕女性健康状态测评",
    "孕期女性健康状态测评",
    "产后女性健康状态测评",
    "围绝经期女性健康状态测评",
  ];
  private zhengxingTypes: any[] = ["高血压辅助辨证", "糖尿病辅助辨证"];
  private zhongdianPieBase: any = {
    grid: {},
    tooltip: {
      formatter: (params: any) => {
        if (params.dataIndex === 1) {
          return "";
        } else {
          return params.name + ":" + params.percent + "%";
        }
      },
    },
    series: [
      {
        type: "pie",
        data: [],
        label: {
          position: "center",
          formatter: (params: any) => {
            if (params.dataIndex === 1) {
              return "";
            } else {
              return params.value;
            }
          },
          fontSize: 26,
        },
        radius: ["50%", "70%"],
      },
    ],
  };
  private get user() {
    return this.$store.state.user;
  }
  private isHasValue(arr: any) {
    let num = 0;
    arr.forEach((ele: any) => {
      num += ele.value;
    });
    return num;
  }
  private get timeOptions() {
    const arr: any = [];
    const currentYear = new Date().getFullYear();
    Array.of(0, 1, 2, 3, 4).forEach((i: any) => {
      arr.push(currentYear - i);
    });
    this.filters["服务人次统计概览"]["机构月服务人次趋势图"]["统计年限"] =
      arr[0];
    this.filters["测评量统计概览"]["机构月测评趋势图"]["统计年限"] = arr[0];
    return arr;
  }
  private get center() {
    return this.$store.state.center;
  }
  /**
   * @description 计算各机构服务量分布的背景
   */
  private calBackground(index: any, item: any, first: any) {
    const max = first.value;
    if (!this.$refs.zongliang as any) {
      return {};
    }
    // 总共40个颜色，除40取余
    const style: any = {};
    style.backgroundColor = this.colors[index % 40];
    if (!style.backgroundColor) {
      style.backgroundColor = "#000000";
    }
    const w = (item.value / max) * (this.$refs.zongliang as any).offsetWidth;
    if (w > 0 && w < 40) {
      style.width = 40 + "px";
    } else {
      style.width = w - 10 + "px";
    }
    return style;
  }
  /**
   * @description 测评量统计概览-机构总体情况
   */
  private getAll() {
    getOverviewData(this).then((res: any) => {
      this.data["平台概览"] = res;
    });
  }
  private bingliTimeChange(e: any) {
    this.filters["病例"] = e;
    this.getStatsBingli();
  }
  private renkouTimeChange(e: any) {
    this.filters["人口统计"] = e;
    this.getStatsPopulation();
  }
  /**
   * @description 病例
   */
  private getStatsBingli() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      病例来源: this.filters["病例"]["病例来源"],
      统计时间: this.filters["病例"]["统计时间"],
    };
    getBingliChart(this, params, loading).then((res: any) => {
      this.data["病例"] = res;
      res["data"].map((item: any) => {
        item.name = item.title;
      });
      this.zongtiPie = {
        color: this.pieColors,
        legend: {
          left: 5,
          orient: "vertical",
          data: res.title,
          icon: "circle",
        },
        grid: {
          width: "90%",
          right: "1%",
        },
        tooltip: {},
        series: [
          {
            type: "pie",
            data: res.data,
            radius: ["50%", "70%"],
            label: {
              show: false,
            },
            center: ["70%", "50%"],
          },
        ],
      };
    });
  }
  /**
   * @description 人口统计
   */
  private getStatsPopulation() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      病例来源: this.filters["人口统计"]["病例来源"],
      统计时间: this.filters["人口统计"]["统计时间"],
    };
    getRenkouData(this, params, loading).then((res: any) => {
      res["性别分布"] = {};
      res["性别分布"]["男"] = res.gender_distribution.data[0].ratio;
      res["性别分布"]["女"] = res.gender_distribution.data[1].ratio;
      res["年龄分布"] = res.age_distribution;
      this.data["人口统计"] = res;
      this.ageOptions = {
        color: this.pieColors,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: 0,
          left: "60",
          right: "60",
          bottom: "20",
          containLabel: false,
        },
        xAxis: {
          show: false,
        },
        yAxis: {
          axisLine: {
            // y轴
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            // 网格线
            show: false,
          },
          type: "category",
          data: res["年龄分布"].name,
        },
        series: [
          {
            name: "数量",
            type: "bar",
            data: res["年龄分布"].data,
            label: {
              show: true,
              position: "right",
              color: "#333",
              formatter: (pa: any) => {
                return (pa.data.ratio * 100).toFixed(2) + "%";
              },
            },
          },
        ],
      };
    });
  }
  private refresh() {
    this.getAll();
    this.getStatsBingli();
    this.getStatsPopulation();
  }
  private mounted() {
    this.refresh();
  }
}
